import * as React from "react"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"



const Header = ({ siteTitle }) => (
  <header className="fixed z-50 w-full p-6">
  <Link to="/"><StaticImage className="w-20 " src="../images/smr_logo.png" placeholder="none"  alt="SMR" /></Link>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
