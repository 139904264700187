import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import {
  FaTiktok,
  FaFacebookSquare,
  FaInstagram,
  FaLinkedinIn,
  FaYoutube,
} from "react-icons/fa"

const Footer = () => {
  return (
    <footer className="flex flex-col items-center py-16 text-sm text-center text-white bg-black md:text-left">
      <div className="mb-6">
        <StaticImage className="w-20 " placeholder="none" src="../images/smr_logo.png" alt="SMR" />
      </div>

      <div className="flex flex-col justify-center mb-6 md:flex-row md:w-full">
        <div className="mx-4 mb-4 md:pr-6 ">
          <div className="font-bold ">
            Social Media Relations
            <br />
            West Coast Office
          </div>
          <div>120 E. 8th St. Los Angeles, CA 90014</div>
        </div>

        <div className="mx-4 md:pl-6 ">
          <div className="font-bold">
            Social Media Relations
            <br />
            East Coast Office
          </div>
          <div>120 Washington St., 2nd Floor. Salem, MA 01970</div>
        </div>
      </div>

      <div>
        <div className="mb-3">Follow Us</div>
        <div className="grid items-center grid-cols-5 gap-4 text-2xl">
          <a
            href="https://www.tiktok.com/@socialmediarelations"
            target="_blank"
            rel="noreferrer"
          >
            <FaTiktok></FaTiktok>
          </a>

          <a
            href="https://www.instagram.com/smrelationsdotcom/"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram></FaInstagram>
          </a>

          <a
            href="https://www.facebook.com/socialmediarelations"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebookSquare></FaFacebookSquare>
          </a>

          <a
            href="https://www.linkedin.com/company/social-media-relations/mycompany/"
            target="_blank"
            rel="noreferrer"
          >
            <FaLinkedinIn></FaLinkedinIn>
          </a>

          <a
            href="https://www.youtube.com/channel/UCV-nkywYEeQ9_aAvZ4ceHbg"
            target="_blank"
            rel="noreferrer"
          >
            <FaYoutube></FaYoutube>
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
